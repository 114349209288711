<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col lg="12" md="12" class="">
        <!-- User Stats -->
        <div class="d-flex align-items-center my-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0 text-primary">
                {{ formatter(emprunts.montant) }}
              </h5>
              <small>Montant + Taux de l'emprunt</small>
            </div>
          </div>

          <!-- <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-warning" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0 text-warning">
                {{ formatter(emprunts.impaye) }}
              </h5>
              <small>Impayé</small>
            </div>
          </div> -->

          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0 text-success">
                {{ (emprunts.taux ===null ? 0 :emprunts.taux ) }}%
              </h5>
              <small>Taux de l'emprunt'</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="CheckIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                <b-badge v-if="emprunts.status === 'remboursé'" variant="success">
                  <!-- {{ emprunts.status }} -->
                    <span>Remboursé</span>
                </b-badge>
                 <b-badge v-if="emprunts.status === 'partiel'" variant="warning">
                  <!-- {{ emprunts.status }} -->
                    <span>Partiel</span>
                </b-badge>
                <b-badge v-if="emprunts.status === 'à rembourser'" variant="danger">
                  <!-- {{ emprunts.status }} -->
                  <span>A rembourser</span>
                </b-badge>
                <!-- <b-badge v-if="emprunts.status === 'à payer'" variant="danger">
                  {{ emprunts.status }}
                </b-badge> -->
              </h5>
              <small>Status</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- 
          -
          -
          -
          -
          -
          -
          -
          -
       -->
      <hr style="width:100%" />
      <!-- Right Col: Table -->
      <b-col lg="12" md="12">
        <b-row>
          <b-col lg="6" md="6">
            <table class="my-2  w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CheckIcon" class="mr-75" />
                  <span class="font-weight-bold"
                    >Libelle : {{ emprunts.libelle }}</span
                  >
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CalendarIcon" class="mr-75" />
                  <span class="font-weight-bold"
                    >Date d'emprunt : {{ emprunts.date_emprunt }}</span
                  >
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="CalendarIcon" class="mr-75" />
                  <span class="font-weight-bold"
                    >Date du 1er remboursement :
                    {{ emprunts.date_remboursement }}</span
                  >
                </th>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon icon="ClockIcon" class="mr-75" />
                  <span class="font-weight-bold"
                    >Delai de remboursement : {{ calculDelai(
                    emprunts.date_emprunt,
                    emprunts.id
                  ) }}</span
                  >
                </th>
              </tr>
              <tr v-if="emprunts.description !==null">
                <th class="pb-50">
                  <feather-icon icon="AlignLeftIcon" class="mr-75" />
                  <span class="font-weight-bold"
                    >Description :
                    <span> {{ emprunts.description}} </span>
                  </span>
                </th>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-col>

      <hr style="width:100%" />

      <!-- liste des reglements -->
      <!-- 
          -
          -
          -
          -
          -
          -
          -
          -
       -->

      <b-col cols="12">
        <div class="my-2">
          <h3>
            <feather-icon icon="TrendingUpIcon" class="mr-75" />
            Liste des dates et montant pour le remboursement
          </h3>
          <br />

          <b-table
            hover
            responsive
            primary-key="id"
            :per-page="state.perPage"
            :current-page="state.currentPage"
            :items="emprunts.remboursements"
            :fields="tableColumns"
            :filter="state.filter"
            show-empty
            empty-text="Aucun reglement effectuez !"
            class="bg-white  text-left"
          >
            <template #cell(created_at)="data">
              <div class="py-1">
                {{ data.item.created_at }}
              </div>
            </template>
          </b-table>

          <div class="mx-2 mb-2 pb-1">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"></span>
              </b-col>

              <!-- Pagination -->

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="state.currentPage"
                  :total-rows="emprunts.remboursements.length"
                  :per-page="state.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>

                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BModal,
  BFormInput,
  VBModal,
  BForm,
  BLink,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import {
  reactive,
  ref,
  onMounted,
  toRef,
  computed,
} from '@vue/composition-api';
import moment from 'moment';
import qDeviseUtils from '@/utils/qDeviseUtils';
import { qDecrypted } from '@/services/qeCrypt';

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    VBModal,
    BForm,
    BImg,
    BLink,
    BForm,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {};
  },

  mounted() {
    this.emprunts = JSON.parse(localStorage.getItem('emprunt'));
    var a = moment(this.emprunts.date_remboursement);
    var b = moment(this.emprunts.date_emprunt);

    this.emprunts.delai = `Dans ${a.diff(b, 'day')} jours`;

    for (let i = 0; i < this.emprunts.remboursements.length; i++) {
      const remb = this.emprunts.remboursements[i];
      remb.montant_remboursement = this.formatter(remb.montant_remboursement);
      remb.created_at = moment(remb.date_remboursement)
        .format('DD-MM-YYYY')
        .toString();
    }
  },
  setup() {
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: '',
      success: false,
      empty: false,
      warring: false,
      loading: false,
    });
    const tableColumns = [
      { key: 'code', label: 'Code', sortable: true },
      { key: 'montant_remboursement', label: 'montant', sortable: true },
      { key: 'created_at', label: 'Date de créatrion', sortable: true },
    ];
    const emprunts = ref([]);
    const empruntReglements = ref([]);
    const perPageOptions = [30, 50, 100, 150, 250, 500];

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem('entreprise_info');
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    const calculDelai = (debut, emprunt_id ) => {

      let diff = {}; // Initialisation du retour

      const __REMB__  = emprunts.value['remboursements'].filter((remb) => { return remb.emprunt_id === emprunt_id})

      for (let i = 0; i < __REMB__.length; i++) {
        const remb = __REMB__[i];
       
         const date1 = new Date();
      const date2 = new Date(remb.date_remboursement);

   if(date1 < date2 && remb.status !== 'regler'){
     let tmp = date2 - date1;

      tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60; // Extraction du nombre de secondes

      tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
      diff.min = tmp % 60; // Extraction du nombre de minutes

      tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
      diff.hour = tmp % 24; // Extraction du nombre d'heures

      tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
      diff.day = tmp;

      break;
   }else{
     diff.day = 0
   }

      
      

     
      }
   
      if (diff.day) {
        return `Moins de ${diff.day} jours`;
      } else if (diff.day === 0) {
        return "Moins de 0 jour";
      }
    }

    return {
      state,
      empruntReglements,
      perPageOptions,
      emprunts,
      tableColumns,
      formatter,
      calculDelai
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
